import React, { useState } from 'react'
import './index.css'
import logo from "../../assets/IpNur.png"
import lines from "../../assets/line.png"
import clip from "../../assets/clip.png"
import car from "../../assets/car.png"
import bigcar from "../../assets/bigcar.png"
import head from "../../assets/head.png"
import village from "../../assets/village.png"
import eco from "../../assets/eco.png"
import conveyor from "../../assets/conveyor.jpg"
import truck from "../../assets/truck.jpg"
import agriculturle from "../../assets/Agriculture.jpg"
import woman from "../../assets/woman.png"
import ecoworld from "../../assets/ecoworld.png"
import ipsmall from "../../assets/IpNursmall.png"
import employee from "../../assets/employee.png"
import phone from "../../assets/phone.png"
import letter from "../../assets/letter.png"
import whatsapp from "../../assets/whatsapp-48.png"
import micrifon from "../../assets/microfon.png" 
import increase from "../../assets/increase.png" 
import idea from "../../assets/idea.png" 
import market from "../../assets/market.png"
import social from "../../assets/social.png" 
import human from "../../assets/human.png" 
import digital from "../../assets/digital.png"  
import videocam from "../../assets/videocam.png" 
import design from "../../assets/design.png" 
import tshir from "../../assets/t-shir.png" 
import rost from "../../assets/rost.png" 
import organization from "../../assets/organization.png" 

import close from '../../assets/close.png'


import logo1 from '../../assets/Allison-Primary-Logo_color.jpg'
import logo2 from '../../assets/Delphi_Technologies.jpg'
import logo3 from '../../assets/DENSO-Secondary-1x3-Logo.jpg'
import logo4 from '../../assets/Febest-red_logo.jpg'
import logo5 from '../../assets/Firestone_eqjaJlv.jpg'
import logo6 from '../../assets/KYB-LOGO.jpg'
import logo7 from '../../assets/Lumileds_Logo_RGB_Color_500.jpg'
import logo8 from '../../assets/Marelli.jpg'
import logo9 from '../../assets/meritor_etM3TXS.jpg'
import logo10 from '../../assets/ORIS-Logo.jpg'
import logo11 from '../../assets/PETRONAS.jpg'
import logo12 from '../../assets/SKF-logo.jpg'
import logo13 from '../../assets/UFI.jpg'
import logo14 from '../../assets/webasto_Logo.jpg'

function Home() {
    const [isMoreVisible, setIsMoreVisible] = useState(false);

    const toggleMoreVisibility = () => {
      setIsMoreVisible(!isMoreVisible);
    };

    const handleContact = () => {
        const message = `Здравствуйте, пишу с сайта. Можете проконсультировать?`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/77474064328?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
      };

      const handleEmailClick = () => {
        const subject = encodeURIComponent('Обращаюсь с сайта.');
        const body = encodeURIComponent('Здравствуйте, пишу с сайта. Можете проконсультировать?');
        window.location.href = `mailto:info@ipnur.kz?subject=${subject}&body=${body}`;
      };

    const [popup, setPopup] = useState(false)
    const [currentLogos, setCurrentLogos] = useState([]);
    const handleOpenPopup = (logos: any) => {
      setPopup(true)
      setCurrentLogos(logos);
    };

    const handleClosePopup = () => {
      setPopup(false)
    }

    const logoSets = {
      automotive: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo10, logo11, logo12, logo13, logo14 ],
      commercial: [logo5, logo11, logo1],
      agriculture: [logo10, logo11],
      construction: [logo12, logo13],
      waste: [logo14, logo3],
    };

  return (
    <div className='all'>

      <div className='menu'>
      <div className='menu-info'>
        <img  className='logo' src={logo}/>
            <button className='info' onClick={handleContact}>
              <img src={clip}/>
              <div>
              ЗАПРОСИТЬ ПРЕЗЕНТАЦИЮ
              </div>
            </button>
      </div>
    </div>

    <div className='container-1'>
    <div className='B2B'>
    B2B Marketing<br /> 
    Communications<br /> 
    Agency
    </div>
    <div className='additionally-info'>
    Агентство полного цикла IPNUR<br />
    оказывает коммуникационные услуги<br />
    компаниям B2B-рынка.
    </div>
    <div className='more'>

    <div className='me' onClick={toggleMoreVisibility}>
      О нас
    </div>

      <div className={`more-ipnur ${isMoreVisible ? '' : 'hidden'}`}>
      Мы практикуем сочетание классических PR-инструментов,<br /> 
      цифровых технологий и других видов маркетинговых<br /> 
      коммуникаций для подготовки эффективных стратегий,<br /> 
      которые полностью отвечают потребностям наших клиентов и<br /> 
      способствуют росту их бизнеса. PR услуги для компаний B2B-<br /> 
      рынка – ключевой вид сервиса агентства IPNUR.
      </div>

    </div>
    </div>

    <div className='menu-2'>
      
      <div className='industry'>Отрасли</div>

      <div className='hover-img'>
        <div className='img-industry'> <img src={car}/> 
        <div className="industry-text">АВТОМОБИЛЬНАЯ ПРОМЫШЛЕННОСТЬ И НЕЗАВИСИМЫЙ РЫНОК АВТОЗАПЧАСТЕЙ</div>
        </div>
        <div className='img-industry'><img src={bigcar}/>
        <div className="industry-text">КОММЕРЧЕСКИЙ ТРАНСПОРТ</div>
        </div>
        <div className='img-industry' ><img src={head}/>
        <div className="industry-text">СЕЛЬСКОЕ ХОЗЯЙСТВО И ПИЩЕВАЯ ПРОМЫШЛЕННОСТЬ</div>
        </div>
        <div className='img-industry' ><img src={village}/>
        <div className="industry-text">СТРОИТЕЛЬНОЕ НАПРАВЛЕНИЕ</div>
        </div>
        <div className='img-industry'><img src={eco}/>
        <div className="industry-text">СБОР И ПЕРЕРАБОТКА ОТХОДОВ</div>
        </div>
      </div>

    </div>
    <div className='container-2'>
        <div className='industry-2'>Отрасли</div>
        <div className='info-indusrty'>
          <div className='info-industry-img' onClick={() => handleOpenPopup(logoSets.automotive)}>АВТОМОБИЛЬНАЯ ПРОМЫШЛЕННОСТЬ И НЕЗАВИСИМЫЙ РЫНОК АВТОЗАПЧАСТЕЙ</div>
          <div className='con2-info'>
              <div className='img-con2' onClick={() => handleOpenPopup(logoSets.commercial)}>КОММЕРЧЕСКИЙ ТРАНСПОРТ</div>
              <div className='img-con2' onClick={() => handleOpenPopup(logoSets.agriculture)}>СЕЛЬСКОЕ ХОЗЯЙСТВО И ПИЩЕВАЯ ПРОМЫШЛЕННОСТЬ</div>
              <div className='img-con2' onClick={() => handleOpenPopup(logoSets.construction)}>СТРОИТЕЛЬНОЕ НАПРАВЛЕНИЕ</div>
              <div className='img-con2' onClick={() => handleOpenPopup(logoSets.waste)}>СБОР И ПЕРЕРАБОТКА ОТХОДОВ</div>
          </div>
        </div>
    </div>
    <div className='container-3'>
      <div className='height'>
        <div className='industry'>
          УСЛУГИ
        </div>
        <div className='optimal'>
          Оптимальный выбор используемых видов маркетинговых коммуникаций зависит от конкретного клиента и его целей.
        </div>
      </div>

      <div className='grid'>

        <div className='block'>
          <div>PUBLIC<br/> RELATIONS</div>
          <img src={micrifon}/>
        </div>

        <div className='block'>
          <div>ONLINE REPUTATION<br/> MARKETING</div>
          <img src={increase}/>
        </div>

        <div className='block'>
          <div>CONTENT<br/> MARKETING</div>
          <img src={idea}/>
        </div>

        <div className='block'>
          <div><br/>РЕКЛАМА</div>
          <img src={market}/>
        </div>

        <div className='block'>
          <div>SOCIAL MEDIA<br/> MARKETING</div>
          <img src={social}/>
        </div>

        <div className='block'>
          <div>INFLUENCERS<br/> MARKETING</div>
          <img src={human}/>
        </div>

        <div className='block'>
          <div><br/> DIGITAL</div>
          <img src={digital}/>
        </div>

        <div className='block'>
          <div>ПРОИЗВОДСТВО<br/> ВИДЕО-КОНТЕНТА</div>
          <img src={videocam}/>
        </div>

        <div className='block'>
          <div><br/> ДИЗАЙН</div>
          <img src={design}/>
        </div>

        <div className='block'>
          <div>ПРОИЗВОДСТВО СУВЕНИРНОЙ<br/> ПРОДУКЦИИ</div>
          <img src={tshir}/>
        </div>

        <div className='block'>
          <div>ПРОВЕДЕНИЕ СТИМУЛИРУЮЩИХ<br/> КАМПАНИЙ</div>
          <img src={rost}/>
        </div>

        <div className='block'>
          <div>ОРГАНИЗАЦИЯ УЧАСТИЯ В<br/> ВЫСТАВКАХ</div>
          <img src={organization}/>
        </div>


      </div>
    </div>

    <div className='footer'>

    <div className='left'>

    <div className='call_me'>Свяжитесь с нами</div>
    
    <div className='contacts'>
        <img src={phone}/>
        <a href="tel:+77474064328">+7 747 406 4328</a>
    </div>

    <div className='contacts'>
        <img src={letter}/>
        <div onClick={handleEmailClick}>info@ipnur.kz</div>
    </div>

    <div className='whatsapp' onClick={handleContact}>
        <img src={whatsapp}/>
    </div>

    <div className='place'>

    <div>
        Офис расположен по адресу:
    </div>

    <br />
    Улица Железняка, 33. Жетысуский район, Алматы 050018/A20K0G5.
    </div>
    </div>


    </div>

    {popup && (
        <div className={`popup`}>
          <div className="popup-row">
            <div className="popup-title">Наши клиенты</div>
            <img className="popup-close" onClick={handleClosePopup} src={close} alt="Close" />
          </div>
          <div className="popup-grid">
            {currentLogos.map((logo, index) => (
              <img key={index} src={logo} alt={`Logo ${index + 1}`} />
            ))}
          </div>
        </div>
      )}

    </div>
  )
}

export default Home